import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {Canvas} from '@react-three/fiber'

import Header from './components/Header';
import Footer from './components/Footer';

import ContainerPlanner from './components/ContainerPlanner';

import './App.css';

function App() {
  const appliedConfig = {
    title: 'Cartonization Optimization',
    unit: 'in.',
    cubicUnit: 'cu.in.',
    showSummaryInfo: true,
    showCalculator: true,
    showPager: true,
    scale: 800 / 46,
    containerName: 'Carton',
    camPosition: {width: 700, height: 900, depth: 800},
  };

  const containerConfig = {
    title: 'Container Optimization',
    unit: 'm',
    cubicUnit: 'cu.m',
    showSummaryInfo: true,
    showCalculator: false,
    showPager: true,
    scale: 800 / 6,
    containerName: 'Container',
    camPosition: {width: 900, height: 600, depth: 700},
  };

  // const selectedConfig = appliedConfig;
  const selectedConfig = containerConfig;

  return (
    <div className="App">
      <Router>
        <Header title={selectedConfig.title} />
        <div style={{backgroundColor: '#eeeeee'}}>
          <div className="mx-3">
            <ContainerPlanner {...selectedConfig} />
          </div>
        </div>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
