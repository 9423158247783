import React from 'react';
import {ReactComponent as ScreenshotIcon} from '../common/icon-screenshot.svg';

function ScreenshotButton(props) {
  return (
    <>
      <button className="text-white px-4 w-auto h-8 bg-red-600 rounded-full hover:bg-red-700 active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none mr-3"
        onClick={e => props.onClick() }>
        <ScreenshotIcon className="text-red-100 w-5 h-5 inline-block" style={{position: 'relative', top: '-2px', right: '-1px'}} />
      </button>
    </>
  );
}

export default ScreenshotButton;
