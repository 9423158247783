import React from 'react';
import {ReactComponent as SkipBackwardIcon} from '../common/skip-backward.svg';
import {ReactComponent as SkipForwardIcon} from '../common/skip-forward.svg';
import {ReactComponent as SkipStartIcon} from '../common/skip-start.svg';
import {ReactComponent as SkipEndIcon} from '../common/skip-end.svg';
import {ReactComponent as PlayIcon} from '../common/play.svg';

function PlayControl(props) {
  return (
    <div>
      <button className="text-white px-3 w-auto h-8 bg-red-600 rounded-l-full hover:bg-red-700 active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none"
        onClick={e => props?.toStart()} title='hide all'>
        <div className=''>
          <SkipBackwardIcon className="text-red-100 w-5 h-5 inline-block" style={{position: 'relative', top: '-2px', right: '-1px'}} />
        </div>
      </button>
      <button className="text-white px-3 w-auto h-8 bg-red-600 hover:bg-red-700 active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none"
        onClick={e => props?.previousGroup()} title='hide previous group'>
        <div className=''>
          <SkipStartIcon className="text-red-100 w-5 h-5 inline-block" style={{position: 'relative', top: '-2px', right: '-1px'}} />
        </div>
      </button>
      <button className="text-white px-3 w-auto h-8 bg-red-600 hover:bg-red-700 active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none"
        onClick={e => props?.previousStep() } title='hide previous step'>
        <div className='transform rotate-180'>
          <PlayIcon className="text-red-100 w-5 h-5 inline-block" style={{position: 'relative', top: '-2px', right: '-1px'}} />
        </div>
      </button>
      <button className="text-white px-3 w-auto h-8 bg-red-600 hover:bg-red-700 active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none"
        onClick={e => props?.nextStep() } title='show next step'>
        <div className=''>
          <PlayIcon className="text-red-100 w-5 h-5 inline-block" style={{position: 'relative', top: '-2px', right: '-1px'}} />
        </div>
      </button>
      <button className="text-white px-3 w-auto h-8 bg-red-600 hover:bg-red-700 active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none"
        onClick={e => props?.nextGroup() } title='show next group'>
        <div className=''>
          <SkipEndIcon className="text-red-100 w-5 h-5 inline-block" style={{position: 'relative', top: '-2px', right: '-1px'}} />
        </div>
      </button>
      <button className="text-white px-3 w-auto h-8 bg-red-600 rounded-r-full hover:bg-red-700 active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none"
        onClick={e => props?.toEnd() } title='show all'>
        <div className=''>
          <SkipForwardIcon className="text-red-100 w-5 h-5 inline-block" style={{position: 'relative', top: '-2px', right: '-1px'}} />
        </div>
      </button>
    </div>
  );
}

export default PlayControl;
